import {
  directionsState,
  speedmeterState,
  timeState
} from '@/stores'

import {
  settings,
  SettingsTypes,
  timeManager
} from '@powerplay/core-minigames'
import {
  fpsLookerState,
  gameSettingsState
} from '@powerplay/core-minigames-ui'
import { getActivePinia } from 'pinia'

export interface FpsDataVuex {
  averageFps: number
  actualFps: number
  actualAverageFps: number
}

export interface SpeedData {
  visible: boolean
  speed: string | number
}

export class StateManager {

  public getFpsStarted(): boolean {

    return fpsLookerState().isStarted ?? false

  }

  public setFpsData(fpsData: FpsDataVuex): void {

    fpsLookerState().$patch(fpsData)

  }

  public allowDirectionState(): void {

    directionsState().show = true

  }

  public setBeforeGameStartPhase(): void {

    gameSettingsState().$patch({
      graphicsSettings: settings.getSetting(SettingsTypes.quality),
      graphicsAuto: settings.getSetting(SettingsTypes.qualityAuto) === 1,
      volume: settings.getSetting(SettingsTypes.sounds) === 1,
      isLeft: settings.getSetting(SettingsTypes.isLeft) === 1,
      controlsType: settings.getSetting(SettingsTypes.controlsType)
    })

  }

  public setUpdateTimeState(): void {

    timeState().$patch({
      timeWithPenalty: timeManager.getGameTimeWithPenaltyInFormat(1),
      time: timeManager.getGameTimeInFormat(1),
      penaltySeconds: timeManager.getPenaltyInfo().seconds
    })

  }

  public hideSpeedVisibility(): void {

    speedmeterState().visible = false

  }

  public updateSpeedData(speedData: SpeedData): void {

    speedmeterState().$patch({
      speed: Number(speedData.speed),
      visible: speedData.visible
    })

  }

  /**
   * Resetovanie pinie
   */
  public resetPinia(): void {

    const exceptions = ['tutorialState', 'inputsState', 'mobileState', 'loadingState', 'gameSettingsState']

    // eslint-disable-next-line
      // @ts-ignore
    getActivePinia()?._s.forEach((s: Store) => {

      if (!exceptions.includes(s.$id)) s.$reset()

    })

  }

}

export const stateManager = new StateManager()
